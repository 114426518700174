<template>
  <div class="animation-number-cover">
    <div id="counter" class="animation-number">
      <template v-for="(digit, index) in getNumber.toString()" :key="index">
        <ul
          v-if="digit !== ','"
          class="digits luckie"
          :class="[`digits-${digit}`, `luckie${digit}`, { 'no-animation': !shouldAnimate }]"
        >
          <li>0</li>
          <li>1</li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
          <li>5</li>
          <li>6</li>
          <li>7</li>
          <li>8</li>
          <li>9</li>
        </ul>
        <ul v-else class="digits luckie">
          <li>,</li>
        </ul>
      </template>
    </div>
    <BaseImg
      v-if="showCoin || showVND"
      :src="`${showVND ? '/assets/images/icon/vnd.svg' : '/assets/images/icon/coin-k.svg'}`"
      class="icon-coin"
      alt="icon-coin"
    />
  </div>
</template>
<script setup>
const { $formatNumberWithCommas } = useNuxtApp()
const props = defineProps({
  number: {
    type: Number,
    default: 0
  },
  fromNumber: {
    type: Number,
    default: 0
  },
  showCoin: {
    type: Boolean,
    default: false
  }
})
const displayNumber = ref(props.fromNumber ? props.fromNumber : 0)
const isFirstRender = ref(false)
const shouldAnimate = ref(false)
watch(
  () => props.number,
  () => {
    displayNumber.value = props.number
    if (isFirstRender.value && props.number > 0) {
      shouldAnimate.value = true
    }
  },
  { immediate: true }
)
onMounted(() => {
  setTimeout(() => {
    isFirstRender.value = true
  }, 1000)
})
onUnmounted(() => {
  shouldAnimate.value = false
  isFirstRender.value = false
})
const getNumber = computed(() => {
  return $formatNumberWithCommas(displayNumber.value, ',')
})
</script>
<style scoped lang="scss">
.animation-number {
  display: flex !important;
  height: 16px;
  overflow: hidden;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  @media screen and (max-width: 768px) {
    height: 14px;
  }
  &.no-animation {
    transition-duration: 0ms;
  }
}
.digits {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  font-size: 16px;
  line-height: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 14px;
  }
  &.no-animation {
    transition-duration: 0ms;
    animation-duration: 0ms;
  }
}
.luckie0 {
  animation-name: luckie-0;
}
.luckie1 {
  animation-name: luckie-1;
}
.luckie2 {
  animation-name: luckie-2;
}
.luckie3 {
  animation-name: luckie-3;
}
.luckie4 {
  animation-name: luckie-4;
}
.luckie5 {
  animation-name: luckie-5;
}
.luckie6 {
  animation-name: luckie-6;
}
.luckie7 {
  animation-name: luckie-7;
}
.luckie8 {
  animation-name: luckie-8;
}
.luckie9 {
  animation-name: luckie-9;
}
@keyframes luckie-0 {
  100% {
    margin-top: 0em;
  }
}
@keyframes luckie-1 {
  100% {
    margin-top: -1em;
  }
}
@keyframes luckie-2 {
  100% {
    margin-top: -2em;
  }
}
@keyframes luckie-3 {
  100% {
    margin-top: -3em;
  }
}
@keyframes luckie-4 {
  to {
    margin-top: -4em;
  }
}
@keyframes luckie-5 {
  100% {
    margin-top: -5em;
  }
}
@keyframes luckie-6 {
  100% {
    margin-top: -6em;
  }
}
@keyframes luckie-7 {
  100% {
    margin-top: -7em;
  }
}
@keyframes luckie-8 {
  100% {
    margin-top: -8em;
  }
}
@keyframes luckie-9 {
  100% {
    margin-top: -9em;
  }
}
</style>
